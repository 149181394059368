import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseApiUrl } from 'const';
import { CreateFile, CreateFileDTO } from 'models/file';
import { throwAnException } from 'utils/api/errors';
import { isNumber } from 'utils/type-guards';

import apiClient from '../auth/apiClient';

export const filesSliceApi = createApi({
	reducerPath: 'files',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: ['files'],
	endpoints: (builder) => ({
		uploadFiles: builder.mutation<CreateFile, CreateFileDTO>({
			queryFn: async (dto) => {
				try {
					const formData = new FormData();
					Object.entries(dto ?? {}).forEach(([key, value]) => {
						formData.append(key, isNumber(value) ? String(value) : value);
					});

					const response = await apiClient.post('/files', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					});

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
	}),
});

export const { useUploadFilesMutation } = filesSliceApi;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISearchState } from './types';

const initialState = {
	searchBy: 'none',
} as ISearchState;

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchBy: (state, action: PayloadAction<Pick<ISearchState, 'searchBy'>>) => {
			state.searchBy = action.payload.searchBy;
		},
	},
});

export default searchSlice.reducer;
export const { setSearchBy } = searchSlice.actions;

import Button from 'components/Button';
import { ROUTES_URLS } from 'const';
import { useRemoveDOMElement } from 'hooks/useRemoveDOMElement';
import React from 'react';
import { Navigate, useNavigate, useRouteError } from 'react-router-dom';
import { hasStatusCodeInMessage, isNativeError } from 'utils/errors-guard';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';

const PageErrorBoundary = () => {
	const navigate = useNavigate();
	const error = useRouteError();

	useRemoveDOMElement({ selector: '#initial-loader', removeOnMount: true });

	const goToHomePage = () => {
		const url = prepareUrl(ROUTES_URLS.HOME);
		navigate(url);
	};

	if (isNativeError(error)) {
		let errorMsg: string | React.ReactNode = '';
		let errorExplanationMsg: string | React.ReactNode = '';

		if (hasStatusCodeInMessage(error, 404)) {
			errorMsg = <>Дані не знайдено.</>;
			errorExplanationMsg = (
				<>
					Сервер працює, але ми не можемо знайти інформацію за цим запитом.
					<br />
					Можливо данних більше не існує або вони були видалені.
				</>
			);
		} else if (hasStatusCodeInMessage(error, 403)) {
			errorMsg = <>Доступ заборонено</>;
			errorExplanationMsg = <>Нажаль ви не маєте прав для доступу на цю сторінку</>;
		} else if (hasStatusCodeInMessage(error, 400)) {
			errorMsg = 'Неправильний запит на сервер';
			errorExplanationMsg = (
				<>
					Сервер працює, але дані за цим запитом не вдається знайти.
					<br />
					Можливо данних більше не існує.
				</>
			);
		} else if (hasStatusCodeInMessage(error, 401)) {
			return <Navigate replace to={ROUTES_URLS.SIGN_IN} />;
		} else {
			errorMsg = 'Щось пішло не за планом!';
			errorExplanationMsg = 'Сталась невідома помилка. Перейдіть будь-ласка на головну, та спробуйте пізніше';
		}

		return (
			<section className={styles.pageErrorBoundary}>
				<h1 className={styles.errorMessage}>
					<strong style={{ color: 'var(--error-500)' }}>Помилка!</strong>&nbsp;
					{errorMsg}
				</h1>
				<h2 className={styles.errorMessageExplanation}>{errorExplanationMsg}</h2>

				<Button variant="default" text="На головну" onClick={goToHomePage} className={styles.redirectButton} />
			</section>
		);
	}

	throw error;
};

export default PageErrorBoundary;

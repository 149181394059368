import NetworkConnection from 'components/NetworkConnection';
import NewVersionAvailable from 'components/NewVersionAvailable';
import { breakPoints } from 'const';
import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import ToasterProvider from 'contexts/ToasterProvider';
import PageLayoutSkeleton from 'layouts/PageLayout/PageLayoutSkeleton';
import React, { Suspense, useLayoutEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';

const App: React.FC = () => {
	const isMobile = useMediaQuery({ maxWidth: `${breakPoints.MOBILE - 1}px` });
	const containerRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!containerRef.current) return;

		if (isMobile) {
			containerRef.current.style.display = 'none';
			return;
		}

		if (containerRef.current.style.display === 'none') {
			containerRef.current.style.display = 'block';
		}
	}, [isMobile]);

	return (
		<>
			<div ref={containerRef} style={{ height: '100%' }}>
				<Suspense fallback={<PageLayoutSkeleton />}>
					<OrderAlertDialoguesProvider>
						<ToasterProvider>
							<RouterProvider router={router} />

							<NewVersionAvailable />
							<NetworkConnection />
						</ToasterProvider>
					</OrderAlertDialoguesProvider>
				</Suspense>
			</div>
			{isMobile && (
				<p
					style={{
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						marginInline: 'auto',
						maxWidth: '65ch',
						fontSize: '20px',
						fontWeight: 500,
						paddingInline: '16px',
					}}
				>
					{
						// eslint-disable-next-line max-len, quotes
						"Мобільна версія тимчасово не підтримується... Будь ласка, скористайтеся пристроєм з більшим розміром екрану або настільним комп'ютером."
					}
				</p>
			)}
		</>
	);
};

export default App;

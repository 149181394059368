import ModalStackProvider from 'contexts/ModalStackProvider';
import { useRemoveDOMElement } from 'hooks/useRemoveDOMElement';
import React from 'react';
import { Outlet } from 'react-router-dom';

const RootLayout: React.FC = () => {
	useRemoveDOMElement({ selector: '#initial-loader', removeOnMount: true });

	return (
		<ModalStackProvider>
			<Outlet />
		</ModalStackProvider>
	);
};

export default RootLayout;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { SelectOption } from 'components/Select/types';
import { API_ENDPOINT, baseApiUrl } from 'const';
import { PRICE_CHANGE_OPTIONS } from 'mock/priceChange';
import type { OriginalPaginatedRequestResult, PaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import { ClientOrder, ClientOrderSchema, ClientOrdersRequestPayload } from 'models/client/client-order';
import type { Option } from 'models/common/options';
import type { PriceChangeDTO } from 'models/IPriceChange';
import { Order, OrderFilters, OrderFiltersSchema, OrderPreview, OrderPreviewSchema, OrderSchema, OrderUpdate } from 'models/order';
import { NormalizedProductSearchResult, ProductSearchResult } from 'models/product';
import { ProductBrand } from 'models/product/brand';
import { CatalogueProduct, CatalogueProductSchema } from 'models/product/catalogue-product';
import type { ProductCategory } from 'models/product/category';
import { LastPriceRequest, LastPriceResult, LastPriceResultSchema } from 'models/product/last-price';
import { CatalogueService, CatalogueServiceSchema } from 'models/service/catalogue-service';
import { loadPaintBaseToneOptions, loadPaintCollectionOptions } from 'pages/OrderServices/optionLoaders';
import { orderQueryKeys } from 'services/queryKeys';
import { throwAnException } from 'utils/api/errors';
import { logger } from 'utils/logger';

import apiClient from '../auth/apiClient';

export const ordersSliceApi = createApi({
	reducerPath: 'orders',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: [orderQueryKeys.orders(), orderQueryKeys.services()],
	endpoints: (builder) => ({
		getOrders: builder.query<PaginatedRequestResult<OrderPreview[]>, string>({
			queryFn: async (queryParams) => {
				try {
					const response = await apiClient.get<OriginalPaginatedRequestResult<OrderPreview[]>>(API_ENDPOINT.allOrders(), {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const result = transformToPaginatedRequestResult<OrderPreview[]>(response.data);
					const validation = OrderPreviewSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
								total: 0,
							},
						};
					}

					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
							total: result.total,
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result) => [orderQueryKeys.ordersOnly(), ...result?.data.map(({ id }) => orderQueryKeys.order(id))],
		}),
		getClientOrders: builder.query<PaginatedRequestResult<ClientOrder[]>, ClientOrdersRequestPayload>({
			queryFn: async ({ id, queryParams }) => {
				try {
					const response = await apiClient.get<OriginalPaginatedRequestResult<ClientOrder[]>>(API_ENDPOINT.clientOrders(id), {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult<ClientOrder[]>(response.data);
					const validation = ClientOrderSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
							},
						};
					}
					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result, _, { id }) =>
				result?.data
					? [orderQueryKeys.orders(), orderQueryKeys.order(id), ...result?.data.map((orders) => orderQueryKeys.order(orders.id))]
					: [],
		}),
		getPriceChangeOptions: builder.query<Option[], void>({
			queryFn: () => {
				const options = PRICE_CHANGE_OPTIONS.map(({ title, ...option }) => {
					return {
						...option,
						label: title,
						value: title,
					};
				}) as Option[];

				return {
					data: options,
				};
			},
		}),
		changeProductsPrice: builder.mutation<void, PriceChangeDTO>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			queryFn: (dto) =>
				new Promise((res) => {
					setTimeout(() => {
						res({ data: null });
					}, 2500);
				}),
			invalidatesTags: (_, __, order) => [orderQueryKeys.ordersOnly(), orderQueryKeys.order(order.orderId)],
		}),
		createOrder: builder.mutation({
			queryFn: async (body) => {
				try {
					const response = await apiClient.post(API_ENDPOINT.allOrders(), body);

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		getOrderById: builder.query({
			queryFn: async (id: string) => {
				if (id === orderQueryKeys.createOrderEntity()) {
					return {
						data: {},
					};
				}

				try {
					const response = await apiClient.get<RequestResult<Order>>(API_ENDPOINT.orderById(id));

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = OrderSchema.safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: null,
						};
					}

					return {
						data: validation.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		refreshOrderLockedStatus: builder.query({
			queryFn: async (id: string) => {
				try {
					await apiClient.get(API_ENDPOINT.orderRefreshLockedStatus(id));

					return {
						data: undefined,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		unlockOrderManually: builder.query({
			queryFn: async (id: string) => {
				try {
					await apiClient.get(API_ENDPOINT.orderUnlockManually(id));

					return {
						data: undefined,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		deleteOrder: builder.mutation({
			queryFn: async (id: string) => {
				try {
					const response = await apiClient.delete(API_ENDPOINT.orderById(id));

					if (response.status !== 204) {
						throw new Error(response.statusText);
					}

					return {
						data: undefined,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				const optimisticOrderDelete = dispatch(
					ordersSliceApi.util.updateQueryData('getOrderById', String(id), (draft) => {
						Object.assign(draft ?? {}, {});
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderDelete.undo();
				}
			},
		}),
		updateOrder: builder.mutation<Order, Partial<OrderUpdate>>({
			queryFn: async (order) => {
				const orderData = { ...order };
				delete orderData.id;

				try {
					const response = await apiClient.patch(API_ENDPOINT.orderById(order.id), orderData);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		// @ DEPRECATED
		updateSubOrder: builder.mutation({
			queryFn: async (order) => {
				const orderData = { ...order };
				delete orderData.id;
				try {
					const response = await apiClient.patch(`/orders/${order.id}`, orderData);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		updateOrderDate: builder.mutation<Order, Pick<OrderUpdate, 'id'>>({
			queryFn: async (order) => {
				try {
					const response = await apiClient.patch(API_ENDPOINT.orderById(order.id));

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		getBrandsFilters: builder.query<ProductBrand[], string>({
			queryFn: async () => {
				try {
					const response = await apiClient.get(API_ENDPOINT.productsBrands());

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getCategoriesFilters: builder.query<ProductCategory[], string>({
			queryFn: async (queryParams) => {
				try {
					const response = await apiClient.get<ProductCategory[]>(API_ENDPOINT.productsCategories(), {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getCatalogueProducts: builder.query({
			queryFn: async (queryParams: string) => {
				try {
					const response = await apiClient.get<OriginalPaginatedRequestResult<CatalogueProduct[]>>(API_ENDPOINT.allProducts(), {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult(response.data);
					const validation = CatalogueProductSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
							},
						};
					}

					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		searchCatalogueProducts: builder.query<NormalizedProductSearchResult, string>({
			queryFn: async (queryParams: string) => {
				try {
					const response = await apiClient.get<ProductSearchResult>(API_ENDPOINT.productsSearch(), {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const data = Object.entries(response.data ?? {}).map(([rootCategoryName, searchResult]) => ({
						rootCategoryName,
						data: searchResult,
					}));

					return {
						data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getPriceTypeList: builder.query({
			query: () => '/price-types',
			// !TEMP - don't need last price type
			transformResponse: (prices) => {
				if (Array.isArray(prices)) {
					return prices.slice(0, prices.length - 1);
				}

				return prices as [];
			},
		}),
		getServices: builder.query<Record<string, CatalogueService>, void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<Record<string, CatalogueService>>(API_ENDPOINT.services());

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = Object.entries(response.data).map(([key, service]) => ({
						data: CatalogueServiceSchema.safeParse(service),
						key,
					}));

					const isValid = validation.every((item) => item.data.success);

					if (!isValid) {
						logger.error(validation.map((item) => item.data?.error?.errors));

						return {
							data: {} as Record<string, CatalogueService>,
						};
					}

					const data = validation.reduce((acc, item) => ({ ...acc, [item.key]: item.data.data }), {}) as Record<string, CatalogueService>;

					return {
						data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: [orderQueryKeys.orders(), orderQueryKeys.services()],
		}),
		getPaintCollectionOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = await loadPaintCollectionOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getPaintBaseToneOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = await loadPaintBaseToneOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getOrdersFiltersData: builder.query<OrderFilters, string>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<OrderFilters>(API_ENDPOINT.orderFilters());

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = OrderFiltersSchema.safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {},
						};
					}

					return {
						data: validation.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getProductLastPrices: builder.mutation<Record<string, string>, LastPriceRequest>({
			queryFn: async (requestDto) => {
				try {
					const response = await apiClient.post<LastPriceResult[]>(API_ENDPOINT.productsLastPrice(), requestDto);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = LastPriceResultSchema.array().safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {},
						};
					}

					const lastPrices = validation.data.reduce((acc, price) => ({ ...acc, [price.productId]: price.lastPrice }), {}) as Record<
						string,
						string
					>;
					return {
						data: lastPrices,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		setOrderOnReserve: builder.mutation<Order, Pick<Order, 'id'>>({
			queryFn: async (order) => {
				try {
					const response = await apiClient.post<Order>(API_ENDPOINT.orderReserve(order.id));

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		setOrderFromReserve: builder.mutation<Order, Pick<Order, 'id'>>({
			queryFn: async (order) => {
				try {
					const response = await apiClient.post<Order>(API_ENDPOINT.orderUnReserve(order.id));

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		copyOrders: builder.mutation<void, string[]>({
			queryFn: async (ordersIdList) => {
				try {
					const response = await apiClient.post<void>(API_ENDPOINT.ordersCopy(), ordersIdList);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: undefined,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
	}),
});

export const {
	useGetOrderByIdQuery,
	useCreateOrderMutation,
	useGetOrdersQuery,
	useUpdateOrderMutation,
	useGetCategoriesFiltersQuery,
	useGetBrandsFiltersQuery,
	useDeleteOrderMutation,
	useGetPriceTypeListQuery,
	useGetPriceChangeOptionsQuery,
	useChangeProductsPriceMutation,
	useGetPaintBaseToneOptionListQuery,
	useGetPaintCollectionOptionListQuery,
	useGetCatalogueProductsQuery,
	useGetOrdersFiltersDataQuery,
	useUpdateSubOrderMutation,
	useGetClientOrdersQuery,
	useGetServicesQuery,
	useGetProductLastPricesMutation,
	useLazyGetPriceTypeListQuery,
	useSetOrderOnReserveMutation,
	useSetOrderFromReserveMutation,
	useUpdateOrderDateMutation,
	useSearchCatalogueProductsQuery,
	useLazyGetCategoriesFiltersQuery,
	useCopyOrdersMutation,
	useRefreshOrderLockedStatusQuery,
	useUnlockOrderManuallyQuery,
	useLazyUnlockOrderManuallyQuery,
} = ordersSliceApi;

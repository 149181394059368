import { APP_MODE, APP_MODE_STORAGE_KEY } from 'const';
import localForage from 'localforage';
import type { AppMode, ModeRecord } from 'models/IApp';
import { isObject } from 'utils/type-guards';

const get = async () => {
	const record = await localForage.getItem<ModeRecord>(APP_MODE_STORAGE_KEY);

	return record;
};

const set = async (mode: AppMode) => {
	await localForage.setItem(APP_MODE_STORAGE_KEY, mode);
};

const hydrate = async () => {
	const record = await get();

	if (!record) return { mode: APP_MODE.online };

	if (isObject<ModeRecord>(record) && 'mode' in record) {
		return record;
	}
};

const getBoolean = async () => {
	const record = await get();

	return {
		isOffline: record?.mode === 'offline',
		isOnline: record?.mode === 'online',
	};
};

export const modeService = {
	get,
	set,
	hydrate,
	getBoolean,
};

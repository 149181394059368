import { ProductIdSchema, ProductUnitIdSchema } from 'models/common/uuid';
import { PriceSchema } from 'models/price';
import { StockSchema } from 'models/stock';
import { z } from 'zod';

import { ProductBrandSchema } from './brand';

export const PointsSchema = z.string().or(z.number()).nullable().optional();
export const ProductUnitSchema = z.object({
	id: ProductUnitIdSchema,
	title: z.string(),
	weight: z.number(),
	volume: z.number(),
	coefficient: z.number(),
	isInt: z.boolean().default(false),
});

export const ProductAvailabilitySchema = z.object({
	stock: StockSchema,
	amount: z.string(),
});
export const ProductLeftoversSchema = ProductAvailabilitySchema;
export const ProductFreeLeftoversSchema = ProductAvailabilitySchema;
export const ProductReserveSchema = ProductAvailabilitySchema;

const BaseProduct = z.object({
	productId: ProductIdSchema,
	amount: z.string(),
	price: z.string(),
	points: PointsSchema,
});

export const CreateProductSchema = BaseProduct;
export const UpdateProductSchema = CreateProductSchema;

export const OrderProductSchema = BaseProduct.omit({ productId: true }).extend({
	id: ProductIdSchema,
	title: z.string().min(1),
	code: z.string().min(1),
	description: z.string().nullish(),
	coefficient: z.string().nullable(),
	sumTax: z.string(),
	sum: z.string(),
	unit: ProductUnitSchema,
	brand: ProductBrandSchema,
	leftovers: z.array(ProductLeftoversSchema),
	freeLeftovers: z.array(ProductLeftoversSchema),
	reserves: z.array(ProductLeftoversSchema),
	prices: z.array(PriceSchema),
	deviation: z.string().nullable(),
	profitability: z.string().nullable(),
	group: z
		.object({
			id: z.string(),
			parent: z.any().nullable(),
			title: z.string().optional().nullable(),
		})
		.nullable()
		.optional(),
});

export type OrderProduct = z.infer<typeof OrderProductSchema>;
export type ProductUnit = z.infer<typeof ProductUnitSchema>;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { type AxiosHeaders, isAxiosError } from 'axios';
import { baseApiUrl } from 'const';
import type { RequestMethod } from 'models/api';
import { logger } from 'utils/logger';

import apiClient from '../auth/apiClient';

export interface ProcessRequestParams<TData = AnyArg> {
	body?: TData;
	url: string;
	method: RequestMethod;
	headers?: RequestInit['headers'];
}

export const postponedRequestsSliceApi = createApi({
	reducerPath: 'postponedRequests',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: ['Postponed'],
	endpoints: (builder) => ({
		processRequest: builder.mutation<AnyArg, ProcessRequestParams<AnyArg>>({
			queryFn: async ({ method, body, headers, url }) => {
				try {
					const normalizedUrl = url.replace(baseApiUrl, '');
					const resolvedUrl = normalizedUrl.startsWith('/') ? normalizedUrl : `/${normalizedUrl}`;

					const methodHandlerName = method.toLowerCase() as Lowercase<RequestMethod>;
					const asyncHandler = apiClient[methodHandlerName];

					const response = await asyncHandler(resolvedUrl, body, {
						headers: {
							...(headers as AxiosHeaders),
						},
					});

					return response.data;
				} catch (error) {
					logger.error(error);
					if (isAxiosError(error)) {
						return {
							error: {
								status: error.response.status,
							},
						};
					}

					return {
						error: {
							status: 500,
							message: 'Невідома помилка при виконанні відкладеного запиту до сервера',
						},
					};
				}
			},
		}),
	}),
});

export const { useProcessRequestMutation } = postponedRequestsSliceApi;

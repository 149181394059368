import { SelectOption } from 'components/Select/types';
import { API_ENDPOINT, CLIENT_PHONE_ASSOCIATED_OPTION_LIST, GROUPS, PRICES_FILTERS, PRICES_TYPE_PRICE } from 'const';
import { RequestResult } from 'models/api';
import { Contract } from 'models/contract';
import apiClient from 'store/reducers/auth/apiClient';
export const loadPhoneAssociatedMessengersOptions = () => CLIENT_PHONE_ASSOCIATED_OPTION_LIST;

export const loadPricesFilterOptions = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(PRICES_FILTERS);
		}, 200);
	});

export const loadGroupsFilterOptions = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(GROUPS);
		}, 200);
	});

export const loadTypeOfPrices = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(PRICES_TYPE_PRICE);
		}, 200);
	});

export const loadContractTypesOptionList = async (): Promise<SelectOption[]> => {
	const res = await apiClient.get<RequestResult<Contract[]>>(API_ENDPOINT.contracts());
	const options = res.data?.data?.map((contract) => ({ label: contract.title, value: contract.id })) as SelectOption[];

	return options;
};
export const loadActivitiesOptionList = async (): Promise<SelectOption[]> => {
	const res = await apiClient.get(API_ENDPOINT.createClientData());
	const options = res.data?.activities?.map((activity) => ({ label: activity.title, value: activity.id })) as SelectOption[];

	return options;
};

import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import Clamp from 'components/Clamp';
import CommentsTooltip from 'components/CommentsTooltip';
import ClientComponent from 'components/Table/TableComponents/ClientComponent';
import { DatetimeCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import ProgressBar from 'components/Table/TableComponents/ProgressBar';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints, ROUTES_URLS } from 'const';
import { ClientPreview } from 'models/client';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { formatAndExtractDateTime } from 'utils/dates';
import { highlightOfflineEntity, prepareUrl } from 'utils/shared';
import { isNumber } from 'utils/type-guards';

import { clientsIcons } from './icons';
import styles from './style.module.css';

const columnHelper = createColumnHelper<ClientPreview>();

export const useClientsColumns = (handleClick: (row: ClientPreview) => void) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const initialColumns = useMemo(
		() => [
			columnHelper.accessor((row) => row.code, {
				id: 'code',
				header: () => <HeaderCell>Код клієнта</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 100,
				enableHiding: true,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Код клієнта',
					getMobileHeaderCell: () => 'Код клієнта',
					icon: <clientsIcons.Case />,
					sortQueryKey: 'code',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.name ?? 'Клієнт', {
				id: 'name',
				header: () => <HeaderCell>Клієнт</HeaderCell>,
				cell: (cell) => (
					<RegularCell>
						<ClientComponent cursor="pointer" onClick={() => handleClick(cell.row.original)} client={cell.getValue()} minWidth="233px" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: true,
				size: 233,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Клієнт',
					getMobileHeaderCell: () => 'Клієнт',
					icon: <clientsIcons.Case />,
					sortQueryKey: 'client',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.segment?.title ?? '—', {
				id: 'segment',
				header: () => <HeaderCell>Сегмент</HeaderCell>,
				cell: (cell) => {
					return <RegularCell>{cell.getValue()}</RegularCell>;
				},
				size: 233,
				enableHiding: true,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Сегмент',
					getMobileHeaderCell: () => 'Сегмент',
					icon: <clientsIcons.Chart />,
					sortQueryKey: 'segment',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.ordersCount, {
				id: 'seeOrdersAction',
				header: () => <HeaderCell>Заявки</HeaderCell>,
				cell: (cell) => {
					const navigate = useNavigate();
					const hasValue = cell.getValue();

					const handleNavigation = (e: React.MouseEvent<HTMLElement>) => {
						e.stopPropagation();
						const url = prepareUrl(ROUTES_URLS.CLIENT_ORDERS, { id: cell.row.original.id });
						navigate(url);
					};

					if (!hasValue) {
						return (
							<RegularCell
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								className={clsx({ disabled: !hasValue })}
							>
								<button type="button" className={clsx('text-sm-regular', styles.cannotSeeOrders)}>
									Переглянути
								</button>
							</RegularCell>
						);
					}

					return (
						<RegularCell onClick={handleNavigation}>
							<button type="button" className={clsx('text-sm-regular', styles.ordersLink)}>
								Переглянути
							</button>
						</RegularCell>
					);
				},
				size: 134,
				enableSorting: false,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Заявки',
					getMobileHeaderCell: () => 'Заявки',
					icon: <clientsIcons.File />,
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.limit, {
				id: 'limit',
				header: () => <HeaderCell>Ліміт</HeaderCell>,
				cell: (cell) => {
					const value = cell.getValue();

					if (isNumber(value)) {
						return (
							<RegularCell className={styles.width}>
								<ProgressBar className={styles.progress} limit={value} value={value} variant="money" />
							</RegularCell>
						);
					}
					const { limit = 0, availableSum = 0 } = value;
					const resolvedValue = availableSum < 0 ? limit + Math.abs(availableSum) : availableSum;

					return (
						<RegularCell className={styles.width}>
							<ProgressBar className={styles.progress} limit={limit} value={resolvedValue} variant="money" />
						</RegularCell>
					);
				},
				size: 250,
				enableSorting: true,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Ліміт',
					getMobileHeaderCell: () => 'Ліміт',
					icon: <clientsIcons.Coin />,
					sortQueryKey: 'limit',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.lastDeal, {
				id: 'lastDeal',
				header: () => <HeaderCell>{isMobile ? 'Ост. прод.' : 'Останній продаж'}</HeaderCell>,
				cell: (cell) => {
					const [date] = formatAndExtractDateTime(cell.getValue());

					return (
						<RegularCell>
							<DatetimeCellContent date={date} />
						</RegularCell>
					);
				},
				size: 164,
				enableSorting: true,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Останній продаж',
					getMobileHeaderCell: () => 'Останній продаж',
					icon: <clientsIcons.Clock />,
					sortQueryKey: 'lastDeal',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.manager?.name ?? '—', {
				id: 'manager',
				header: () => <HeaderCell>Менеджер</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 233,
				enableSorting: true,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Менеджер',
					getMobileHeaderCell: () => 'Менеджер',
					icon: <clientsIcons.User />,
					sortQueryKey: 'manager',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => ('edrpouCode' in row ? row.edrpouCode : '—'), {
				id: 'edrpouCode',
				header: () => <HeaderCell>Код ЄДРПОУ</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 233,
				enableSorting: true,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Код ЄДРПОУ',
					getMobileHeaderCell: () => 'Код ЄДРПОУ',
					icon: <clientsIcons.EdrpouCode />,
					sortQueryKey: 'edrpouCode',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor(
				(row) => {
					if (!row.activities?.length) return '—';

					return (
						<ol>
							{row.activities.map((activity) => (
								<li key={activity.id}>{activity.title}</li>
							))}
						</ol>
					);
				},
				{
					id: 'activityType',
					header: () => <HeaderCell>Вид діяльності</HeaderCell>,
					cell: (cell) => <RegularCell className={styles.activityCell}>{cell.getValue()}</RegularCell>,
					size: 233,
					enableSorting: false,
					enableHiding: true,
					meta: {
						getCanRenderOnMobile: () => true,
						getVisibilityToggleTitle: () => 'Вид діяльності',
						getMobileHeaderCell: () => 'Вид діяльності',
						icon: <clientsIcons.ActivityType />,
						getTdCssClassName: highlightOfflineEntity,
					},
				},
			),
			columnHelper.accessor((row) => row.createdAt, {
				id: 'createdAt',
				header: () => <HeaderCell>Дата створення</HeaderCell>,
				cell: (cell) => {
					const [formattedDate, formattedTime] = formatAndExtractDateTime(cell.getValue());

					return (
						<RegularCell justify={isMobile ? 'start' : 'center'}>
							<DatetimeCellContent date={formattedDate} time={formattedTime} />
						</RegularCell>
					);
				},
				size: 233,
				enableSorting: true,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Дата створення',
					getMobileHeaderCell: () => 'Дата створення',
					icon: <clientsIcons.CreationDate />,
					sortQueryKey: 'createdAt',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.clientStatus || '—', {
				id: 'clientStatus',
				header: () => <HeaderCell>Стан клієнта</HeaderCell>,
				cell: (cell) => <RegularCell>{String(cell.getValue())}</RegularCell>,
				size: 233,
				enableSorting: false,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Стан клієнта',
					getMobileHeaderCell: () => 'Стан клієнта',
					icon: <clientsIcons.ClientStatus />,
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			columnHelper.accessor((row) => row.comment, {
				id: 'comment',
				header: () => <HeaderCell>Коментар</HeaderCell>,
				cell: (cell) => {
					const comment = cell.getValue();
					const commentEl = <span dangerouslySetInnerHTML={{ __html: comment }} />;

					return (
						<RegularCell>
							{!!comment && (
								<CommentsTooltip text={commentEl}>
									<Clamp as="span" lines={2}>
										{commentEl}
									</Clamp>
								</CommentsTooltip>
							)}
						</RegularCell>
					);
				},
				size: 233,
				enableSorting: true,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Коментар',
					getMobileHeaderCell: () => 'Коментар',
					icon: <clientsIcons.Comment />,
					sortQueryKey: 'comment',
					getTdCssClassName: highlightOfflineEntity,
				},
			}),
			// columnHelper.accessor('chooseClient', {
			// 	id: 'chooseClient',
			// 	header: () => <HeaderCell>Обрати клієнта:</HeaderCell>,
			// 	size: 100,
			// 	enableHiding: true,
			// 	meta: {
			// 		getCanRenderOnMobile: () => false,
			// 	},
			// }),
			// columnHelper.accessor('sort', {
			// 	id: 'sort',
			// 	header: () => <HeaderCell>Сортувати А - Я</HeaderCell>,
			// 	size: 100,
			// 	enableHiding: true,
			// 	meta: {
			// 		getCanRenderOnMobile: () => false,
			// 	},
			// }),
		],
		[isMobile],
	);

	return initialColumns;
};

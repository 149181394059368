const { log, error, warn } = console;
import axios, { isAxiosError } from 'axios';

import { isObject } from './type-guards';

const client = axios.create({
	baseURL: 'https://store.jezzarah.com/api/logger',
});

function errorReplacer(_, value) {
	if (value instanceof Error) {
		return {
			messageType: 'NATIVE_ERROR',
			message: value.message,
			name: value.name,
			stack: value.stack,
		};
	}
	if (isAxiosError(value)) {
		return {
			messageType: 'AXIOS_ERROR',
			message: value.response.data?.message,
			name: value.name,
			stack: value.stack,
		};
	}

	if (isObject(value)) {
		return {
			messageType: 'INFO',
			...value,
		};
	}

	return value;
}

export const logger = {
	info: log,
	error,
	warn,
	debugOrder: (errors: Record<string, AnyArg>) => {
		const hasErrors = Object.keys(errors || {}).length > 0;

		const message = hasErrors ? 'Has internal validation errors' : 'No internal validation errors';
		const styles = hasErrors ? 'background: red; color: white;' : 'background: green; color: white;';

		log(`%c ${message}`, styles, errors);
	},
	logPolling: (msg: string) => {
		const styles = 'color: rebeccapurple;';

		log(`%c ${msg}`, styles);
	},
	debug: (e) => {
		const payload = { message: JSON.stringify(e, errorReplacer) };
		client.post('/lograw', payload).catch(log);
	},
};

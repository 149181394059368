import { ServiceBrandIdSchema, ServiceCategoryIdSchema, ServiceGroupIdSchema, ServiceIdSchema } from 'models/common/uuid';
import { z } from 'zod';

const ServiceGroupParentSchema = z.object({
	id: z.string().min(1),
	title: z.string().min(1),
});
export const ServiceGroupSchema = z.object({
	id: ServiceGroupIdSchema,
	title: z.string().min(1),
	parent: z.any().nullish(),
});

export const ServiceBrandSchema = z.object({
	id: ServiceBrandIdSchema,
	title: z.string().min(1),
});
export const ServiceCategorySchema = z.object({
	id: ServiceCategoryIdSchema,
	title: z.string().min(1),
	parent: ServiceGroupParentSchema.extend({
		parent: ServiceGroupParentSchema.extend({
			parent: z.string().nullable(),
		}).nullable(),
	}).nullable(),
});

const BaseServiceSchema = z.object({
	serviceId: ServiceIdSchema,
	amount: z.string(),
	price: z.string(),
});

export const CreateServiceSchema = BaseServiceSchema;
export const UpdateServiceSchema = CreateServiceSchema;

export const OrderServiceSchema = BaseServiceSchema.omit({ serviceId: true }).extend({
	id: ServiceIdSchema,
	title: z.string().min(1),
	code: z.string().min(1),
	description: z.string().nullable(),
	comment: z.string().nullable(),
	sumTax: z.string(),
	sum: z.string(),
	group: ServiceGroupSchema.nullable(),
	brand: ServiceBrandSchema.nullable(),
	category: ServiceCategorySchema.nullable(),
});

export type OrderService = z.infer<typeof OrderServiceSchema>;

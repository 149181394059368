import UserPermissionsLoader from 'components/UserPermissionsLoader';
import { ROUTES_URLS } from 'const';
import PageLayout from 'layouts/PageLayout';
import RootAuthLayout from 'layouts/RootAuthLayout';
import RootLayout from 'layouts/RootLayout';
import Page404 from 'pages/404';
import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import PageErrorBoundary from './components/ErrorBoundaries/PageErrorBoundary';
import RootErrorBoundary from './components/ErrorBoundaries/RootErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import RestrictedRoute from './components/RestrictedRoute';
import { SharedLayoutRoutes } from './PrivateRoutes';
import { AuthRoutes } from './restrictedRoutes';

const Home = lazy(() => import('pages/Home'));

export const router = createBrowserRouter([
	{
		element: <UserPermissionsLoader />,
		children: [
			{
				element: <RootLayout />,
				ErrorBoundary: RootErrorBoundary,
				shouldRevalidate: () => false,
				children: [
					{
						path: ROUTES_URLS.HOME,
						errorElement: <PageErrorBoundary />,
						children: [
							{
								element: <PrivateRoute />,
								children: [
									{
										element: <Home />,
										index: true,
									},
								],
							},
							{
								element: <PrivateRoute />,
								children: [
									{
										element: <PageLayout />,
										errorElement: <PageErrorBoundary />,
										children: [...SharedLayoutRoutes],
									},
								],
							},
						],
					},
					{
						path: '*',
						element: <Page404 />,
					},
				],
			},
		],
	},
	{
		element: <RootAuthLayout />,
		children: [
			{
				element: <RestrictedRoute />,
				children: [...AuthRoutes],
			},
		],
	},
]);

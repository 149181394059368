export const isObject = <TValue>(value: unknown): value is Record<string, unknown> & TValue =>
	typeof value === 'object' && value !== null && !Array.isArray(value);

export const isFunction = (value: unknown): value is (...arg: AnyArg[]) => AnyArg => typeof value === 'function';

export const isNumber = (value: unknown): value is number => typeof value === 'number';
export const isNullish = (value: unknown): value is undefined | null => value === undefined || value === null;
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

export const assertNever = (value: never, noThrow?: boolean): never => {
	if (noThrow) {
		return value;
	}

	throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
};
interface AbortError {
	name: string;
}
export const isRequestAborted = (error: unknown): error is AbortError => {
	return isObject(error) && 'name' in error && error.name === 'AbortError';
};

export const isPromise = <TData>(p: unknown): p is Promise<TData> => {
	return (p && Object.prototype.toString.call(p) === '[object Promise]') || p instanceof Promise;
};

export const isRTKQueryPromise = (p: unknown): p is { unwrap: () => Promise<void> } => {
	return isObject(p) && 'unwrap' in p;
};

export const isAsyncFunction = <TReturn>(fn: unknown): fn is (...arg: AnyArg[]) => Promise<TReturn> => {
	return isFunction(fn) && fn[Symbol.toStringTag] === 'AsyncFunction';
};
export const isURLSearchParams = (value: unknown): value is URLSearchParams => {
	return value instanceof URLSearchParams;
};

import Button from 'components/Button';
import { ROUTES_URLS } from 'const';
import { useRemoveDOMElement } from 'hooks/useRemoveDOMElement';
import React from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import Logo from 'static/images/Logomark.svg';
import { prepareUrl } from 'utils/shared';
import { isObject } from 'utils/type-guards';

const RootErrorBoundary = () => {
	const navigate = useNavigate();
	const error = useRouteError();

	useRemoveDOMElement({ selector: '#initial-loader', removeOnMount: true });

	const redirectToHome = () => {
		const url = prepareUrl(ROUTES_URLS.HOME);

		navigate(url, { replace: true });
	};

	let msg = '';
	let stack = '';

	if (isRouteErrorResponse(error)) {
	} else if (isObject(error) && 'stack' in error && 'message' in error) {
		msg = error.message as string;
		stack = error.stack as string;
	}

	return (
		<>
			<style>
				{`
        .root-error-boundary {
          display: flex;
          flex-direction: column;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 40px;
          padding-bottom: 40px;
          height: 99vh;
          background: #C9D6FF;  /* fallback for old browsers */
          background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        };
      `}
			</style>
			<style>
				{`
        .root-error-boundary button {
          margin-top: 60px;
          margin-left: auto;
          margin-right: auto;
        };
      `}
			</style>

			<div className="root-error-boundary">
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '12px' }}>
					<img src={Logo} alt="Логотип компанії UnitB" />
					<h2>UnitB</h2>
				</div>
				<h1 style={{ color: 'var(--error-500)', textAlign: 'center' }}>Development Error</h1>
				<strong>Сталася непередбачувана помилка, але все під контролем.</strong>
				<br />
				<br />

				{!!msg && <p>{msg}</p>}
				{!!stack && (
					<div>
						<pre>{stack}</pre>
					</div>
				)}

				<Button variant="default" text="На головну" onClick={redirectToHome} />

				<small style={{ fontSize: '14px', marginTop: 'auto', marginLeft: 'auto' }}>
					<span>Gradient from</span>&nbsp;
					<a
						href="https://uigradients.com/#Dull"
						rel="noopener noreferrer"
						target="_blank"
						style={{ textDecoration: 'underline', color: 'blue' }}
					>
						uigradients
					</a>
				</small>
			</div>
		</>
	);
};

export default RootErrorBoundary;
